/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import "./card.css"

function Card({ description, meta, link, title }) {
  const tags = (meta.tags || []).map((tag, i) => <span className="badge" key={`${link}-tag-${i}`}>{tag}</span>);

  return (
    <div className="card">
      <h3><Link to={link}>{title}</Link></h3>
      <p>{description}</p>
      {tags}
      {/*{!!meta.date ? <div>{meta.date}</div> : null}*/}
    </div>
  )
}

Card.defaultProps = {
  meta: {},
  description: ``
}

Card.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.object,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default Card
