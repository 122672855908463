import React from "react"
import { graphql, Link } from "gatsby"
import * as _ from "lodash"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Card from "../components/card"

const IndexPage = ({ data }) => {
  const { allMarkdownRemark } = data;
  const pages = allMarkdownRemark.edges.map(edge => edge.node.frontmatter);
  const cards = _.sortBy(pages, 'order').map((page, i) => (
    <Card title={page.title} link={page.slug} meta={{ tags: page.tags, date: page.date }} key={`page-${i}`}/>
  ))

  return (
    <Layout>
      <SEO title="Home"/>
      <h1>Welcome!</h1>
      <p>Below you will find a list of articles that pertain to the Docker/Kubernetes/Helm workshop!</p>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: 'wrap' }}>
        {cards}
        {/*<Card title="Set up WSL 2" link="/blog/set-up-wsl-2/" meta={{ tags: [ 'windows' ] }}/>*/}
        {/*<Card title="Set up Docker" link="/posts/set-up-docker/" meta={{ tags: [ 'windows', 'linux', 'mac' ] }}/>*/}
        {/*<Card title="Enable Kubernetes" link="/posts/enable-kubernetes/"*/}
        {/*      meta={{ tags: [ 'windows', 'linux', 'wsl', 'mac' ] }}/>*/}
      </div>
      {/*<div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>*/}
      {/*  <Image context={{ id: 'gatsby-astronaut.png' }}/>*/}
      {/*</div>*/}
      {/*<Link to="/page-2/">Go to page 2</Link>*/}
    </Layout>
  )
}

export const query = graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            frontmatter {
              title
              slug
              date
              tags
              order
            }
          }
        }
      }
    }
`

export default IndexPage
